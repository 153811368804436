import styled from "styled-components"
import { Link } from "gatsby"

export const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const ExternalLink = styled.a`
  text-decoration: none;
  color: lightgray;
  &:hover {
    color: orange;
  }
`

export const InternalLink = styled(Link)`
  text-decoration: none;
  color: lightgray;
  &:hover {
    color: orange;
  }
`
