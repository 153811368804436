import React from "react"
import { ExternalLink, InternalLink, LinkWrapper } from "../components/link"

const Links = () => (
  <LinkWrapper>
    <ExternalLink
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.linkedin.com/in/timothy-brunette/"
    >
      [linkedin]
    </ExternalLink>
    <InternalLink to="/contact">[contact]</InternalLink>
  </LinkWrapper>
)

export default Links
