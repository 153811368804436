import React from "react"
import styled from "styled-components"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Main from "../../components/Main"
import Links from "../../components/Links"
import Title from "../../components/Title"
import Header from "../../components/Header"
import BodyWrapper from "../../components/BodyWrapper"

const StyledParagraph = styled.p`
  text-align: center;
`

export default () => (
  <Layout>
    <SEO title="Success" />
    <Main>
      <Header>
        <Title text="timothy brunette" to="/" />
        <Links />
      </Header>
      <BodyWrapper>
        <StyledParagraph>
          Thanks for reaching out. I'll get back to you soon.
        </StyledParagraph>
      </BodyWrapper>
    </Main>
  </Layout>
)
